import React, {useCallback, useMemo, useState} from 'react'
import {PublicTableDTO} from "../home/types";
import {Button, Card, Divider, Flex, Image, Modal, Progress, Typography} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import {Label} from "../commons/translations/Label";
import {useTranslation} from "react-i18next";
import {ReservationButton} from "./ReservationButton";
import DragonIcon from "../commons/icons/DragonIcon";
import DiceIcon from "../commons/icons/DiceIcon";
import CardsIcon from "../commons/icons/CardsIcon";
import {Link} from "react-router-dom";
import {InfoCircleFilled} from "@ant-design/icons";


export type TableCardProps = {
    eventTag: string;
    table: PublicTableDTO;
}

export const TableCard: React.FC<TableCardProps> = (props) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const {table} = props;
    const [expanded, setExpanded] = useState<boolean>(false);
    const toggleExpand = useCallback(() => {setExpanded(!expanded)}, [expanded, setExpanded]);
    const canAcceptReservations = useMemo<boolean>(() => table.reserved < table.seatsMax, [table.reserved, table.seatsMax])

    const progress = useMemo<number>(() => Math.floor((table.reserved/table.seatsMax) * 100), [table.reserved, table.seatsMax]);

    return (
        <Card title={
            <div onClick={() => {
                if (table.game) {
                    setOpen(!open)
                }
            }}>
                <Button type={"default"} style={{width:'100%'}} icon={
                    table.game?.type.tag === 'ttrpgs' ? <DragonIcon /> :
                    table.game?.type.tag === 'card-games' ? <CardsIcon /> :
                    table.game?.type.tag === 'board-games' ? <DiceIcon /> :
                    <></>
                }>
                    <Typography.Text underline strong style={{fontSize: '1.2em'}} >{table.game?.title}</Typography.Text>
                    <InfoCircleFilled />
                </Button>
            </div>}
            style={{margin: '3px', borderColor: '#b8b8b5'}} key={table.id}
        >
            <Modal open={open} closable={true} footer={null} onCancel={() => setOpen(false)}>
                <Typography.Title level={5}>{table.game?.title}</Typography.Title>
                <Typography.Text>{table.game?.releaseYear}</Typography.Text>
                {!!table.game?.mediaUrl &&
                    <Flex vertical={false} justify={"center"}>
                        <Image src={table.game.mediaUrl} alt={'session media'} style={{maxHeight: '20em'}} preview={false}/>
                    </Flex>
                }
                <Typography.Paragraph >{table.game?.description}</Typography.Paragraph>
                {!!table.game?.moreInfo &&
                    <Button type={"primary"} style={{width: '100%'}}>
                        <Link to={table.game.moreInfo} target={"_blank"}>{t('common.learn.more')}</Link>
                    </Button>
                }
            </Modal>
            <>
                <Typography.Text strong>{table.title}</Typography.Text>
                <br/>
                {!!table.mediaUrl &&
                    <>
                        <Flex vertical={false} justify={"center"}><Image src={table.mediaUrl} alt={'session media'} style={{maxHeight: '20em'}} preview={false}/></Flex>
                        <br/>
                    </>
                }
                <Label trl={'reservation.card.hosted.by'} />
                <Typography.Text italic >{' ' + table.facilitator}</Typography.Text>
                <br/>
                <Paragraph
                    ellipsis={expanded ? false : {
                        rows: 1,
                    }}
                >
                    {table.description}
                </Paragraph>
                <Button type={"link"} onClick={toggleExpand}>{expanded ? t('reservation.card.show.less') : t('reservation.card.learn.more')}</Button>
                <Divider type={"horizontal"} />
                <div>
                    <Progress percent={progress} steps={table.seatsMax} showInfo={false} />
                    <br/>
                    {`${table.reserved} / ${table.seatsMax} `}
                    <Label trl={'reservation.card.seats.reserved'} />
                </div>
                <br/>
                {!canAcceptReservations && <Label strong trl={'reservation.card.seats.full'} />}
                {canAcceptReservations && <ReservationButton table={table} />}
            </>
        </Card>
    );
}