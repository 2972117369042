import React, {useMemo} from 'react';
import {useParams} from "react-router";
import {useQuery} from "react-query";
import axios, {AxiosResponse} from "axios";
import {PublicEventDTO} from "../home/types";
import {CommonLayout} from "../commons/CommonLayout";
import {PendingDataGuard} from "../commons/Indicators";
import {ReservationPage} from "../reservation/EventReservations";

export const TableReservationByTag: React.FC = () => {
    const { eventTag, tableTag } = useParams();
    const {data: target, isLoading, isError} = useQuery(`public-event-id-${eventTag}`,
        () => axios.get<any,AxiosResponse<PublicEventDTO>>(`/api/events/tags/${eventTag}` ).then(r => r.data),
        {
            enabled: !!eventTag
        });

    const eventWithJustOneTable = useMemo<PublicEventDTO | undefined>(
        () => {
            if (!target) {
                return undefined;
            }
            const table = target &&
                target.tables
                    .find(t => t.tag === tableTag);
            return {...target, tables: [table!]};
        }, [target]);

    const containsMoreTables = useMemo<boolean>(() => !!(target && target.tables.length > 1), [target]);

    return (
        <CommonLayout>
            <PendingDataGuard isLoading={isLoading} isError={isError} data={target} >
                <ReservationPage target={eventWithJustOneTable!} containsMoreTables={containsMoreTables} />
            </PendingDataGuard>
        </CommonLayout>
    );
}