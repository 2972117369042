import React from 'react';
import {useParams} from "react-router";
import {CommonLayout} from "../commons/CommonLayout";
import {Button, Descriptions, Divider, Flex, Image, Typography} from "antd";
import {CalendarOutlined,  InfoOutlined, PushpinOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {useQuery} from "react-query";
import axios, {AxiosResponse} from "axios";
import {PublicEventDTO} from "../home/types";
import {TableCard} from "./TableCard";
import {PendingDataGuard} from "../commons/Indicators";
import {Link} from "react-router-dom";
import {Label} from "../commons/translations/Label";

export const EventReservationsById: React.FC = () => {
    const { eventId } = useParams();
    const {data: target, isLoading, isError} = useQuery(`public-event-id-${eventId}`,
        () => axios.get<any,AxiosResponse<PublicEventDTO>>(`/api/events/${eventId}` ).then(r => r.data),
        {
           enabled: !!eventId
        });

    return (
        <CommonLayout>
            <PendingDataGuard isLoading={isLoading} isError={isError} data={target} >
                <ReservationPage target={target!} />
            </PendingDataGuard>
        </CommonLayout>
    );
}

export const EventReservationsByTag: React.FC = () => {
    const { eventTag } = useParams();
    const {data: target, isLoading, isError} = useQuery(`public-event-id-${eventTag}`,
        () => axios.get<any,AxiosResponse<PublicEventDTO>>(`/api/events/tags/${eventTag}` ).then(r => r.data),
        {
           enabled: !!eventTag
        });

    return (
        <CommonLayout>
            <PendingDataGuard isLoading={isLoading} isError={isError} data={target} >
                <ReservationPage target={target!} />
            </PendingDataGuard>
        </CommonLayout>
    );
}

export const ReservationPage: React.FC<{target: PublicEventDTO, containsMoreTables?: boolean}> = (props) => {
    const {target, containsMoreTables} = props;
    return (
        <>
            <Flex justify={"center"}>
                <Typography.Title level={3} >{target.title}</Typography.Title>
            </Flex>
            {target.mediaUrl &&
                <Flex justify={"center"}>
                    <Image src={target.mediaUrl} preview={false} />
                </Flex>
            }
            <Divider style={{backgroundColor: '#b8b8b5'}}/>
            <Descriptions colon={false}>
                <Descriptions.Item label={<span style={{height: '10px'}}><CalendarOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></span>}>
                    <Typography.Text italic>{dayjs(target.startsAt).format('DD/MM/YYYY HH:mm')}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label={<span style={{height: '10px'}}><PushpinOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></span>}>
                    {target.navigationLink
                        ? (<Typography.Link href={target.navigationLink} >{target.location}</Typography.Link>)
                        : (<Typography.Text>{target.location}</Typography.Text>)
                    }
                </Descriptions.Item>
                {!!target.description &&
                    <Descriptions.Item label={<span style={{height: '10px'}}><InfoOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></span>}>
                        <Typography.Paragraph>
                            {target?.description}
                        </Typography.Paragraph>
                    </Descriptions.Item>
                }
            </Descriptions>
            {
                target.tables
                    .sort(table => table.position)
                    .map(table =>
                        <TableCard table={table} key={table.id} eventTag={target.tag || 'missing-event-tag'}/>
                    )
            }
            {containsMoreTables &&
                <Flex justify={"center"}>
                    <Button type={"default"} style={{marginTop: '1em', width: '95%'}}><Link to={`/reserve/${target.tag}`}><Label trl={"reservation.see.more.tables"}/></Link></Button>
                </Flex>
            }
        </>
    )
}