import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {defaultFormItemLayout} from "../commons/forms";
import {Button, Flex, Form, Input, InputNumber, Modal, Result, Spin} from "antd";
import TextArea from "antd/es/input/TextArea";
import {useForm} from "antd/lib/form/Form";
import {ReservationRequest} from "./types";
import {PublicTableDTO} from "../home/types";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {Label} from "../commons/translations/Label";
import {useLocaleContext} from "../commons/translations/LocaleContext";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Link} from "react-router-dom";
import DonateIcon from "../commons/icons/DonateIcon";
import {CheckCircleOutlined} from "@ant-design/icons";
import Texty from 'rc-texty';

export const ReservationForm: React.FC<{table: PublicTableDTO}> = (props) => {
    const {table} = props;
    const {locale} = useLocaleContext();
    const {t} = useTranslation();
    const [reserved, setReserved] = useState<boolean>(false);
    const [file, setFile] = useState<string | undefined>(undefined);
    const maxSeats = useMemo<number>(() => table.seatsMax - table.reserved, [table.reserved, table.seatsMax])
    const [form] = useForm<ReservationRequest>();
    const [working, setWorking] = useState<boolean>(false);
    const [finished, setFinished] = useState<boolean>(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [showDonation, setShowDonation] = useState<boolean>(false);

    useEffect(() => {
        if (!form.getFieldsValue().seats) {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                seats: 1
            });
        }
    }, [form]);

    useEffect(() => {
        form.setFieldsValue({
            ...form.getFieldsValue(),
            locale: locale?.locale,
        })
    }, [form, locale]);

    const labels = useMemo(() => {
        return {
            name: t('reservation.form.fields.name'),
            seats: t('reservation.form.fields.seats'),
            contact: t('reservation.form.fields.contact'),
            contactPlaceholder: t('reservation.form.fields.contact.placeholder'),
            failure: t('common.failure'),
            rejection: t('reservation.rejected')
        }
    }, [t]);

    const reserve = useCallback(async (data: ReservationRequest) => {
        if (working || !executeRecaptcha) {
            return;
        }
        setWorking(true);
        executeRecaptcha('reservation')
        .then(captcha => {
            const finalData = {
                ...data,
                captcha
            }
            return axios.post(`/api/reservations/${table.id}`, finalData, {
                responseType: "blob"
            });
        })
        .then(r => {
            const fileName = r.headers['x-reservation-file-id'];
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(r.data);
            link.download=fileName;
            link.click();
            setReserved(true);
            setFile(fileName);
            setWorking(false);
            setFinished(true);
        })
        .catch(e => {
            const messsage = e?.response?.status === 400 ?
                e?.message || labels.failure : labels.failure;
            Modal.error({
                title: labels.rejection,
                content: messsage
            });
            setWorking(false);
        })
    }, [form, table.id, working, setWorking, executeRecaptcha]);

    return (
        <>
            <Modal open={working} closable={false} footer={<></>} >
                <Result status={"info"}>
                    <Spin />
                    <Label trl={'common.loading'} />
                </Result>
            </Modal>
            {finished &&
                <div style={{padding: '2.5%'}}>
                    <Flex justify={"center"} align={"center"} vertical={true}>
                        <CheckCircleOutlined style={{color: 'limegreen', fontSize: '8em'}}/>
                        <br/>
                        <Texty type={"scaleY"} mode={"smooth"} onEnd={({key}) => {
                            if (key.startsWith(':')) {
                                setShowDonation(true);
                            }
                        }}>{t('reservation.donation.prompt')}</Texty>
                        <br/>
                        {showDonation &&
                            <>
                                <Button size="large" icon={<DonateIcon/>} type={"primary"}>
                                    <Link target={"_blank"}
                                          to={'https://buymeacoffee.com/therpglodge'}>{t('reservation.donation.action')}</Link>
                                </Button>
                                <p><Label trl={'reservation.bring.pdf'}/></p>
                                <Label strong trl={'reservation.file.name'}/><b>{' ' + file}</b>
                                <br/>
                                <a href={`/api/reservations/${file}`}
                                   download={file}>{t('reservation.download.file')}</a>
                            </>
                        }
                    </Flex>
                </div>
            }
            {!finished && !reserved && !working &&
                <Form form={form} {...defaultFormItemLayout} onFinish={reserve}>
                    <Form.Item hidden name="locale"/>
                    <Form.Item name="name" rules={[{required: true}]} label={labels.name}>
                        <Input disabled={reserved} required/>
                    </Form.Item>
                    <Form.Item name="seats" rules={[{required: true}]} label={labels.seats}>
                        <InputNumber min={1} max={maxSeats} disabled={reserved} required/>
                    </Form.Item>
                    <Form.Item name="contactInfo" label={labels.contact} rules={[{required: true}]}>
                        <TextArea placeholder={labels.contactPlaceholder}
                                  disabled={reserved}
                                  showCount={true}
                                  maxLength={100}
                                  required
                                  rows={5}
                        />
                    </Form.Item>
                    <Button type={"primary"}
                            onClick={form.submit}
                            disabled={reserved}
                            style={{width: '100%', backgroundColor: 'limegreen'}}>
                        {t('reservation.submit')}
                    </Button>
                </Form>
            }
        </>

    )
}