import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {QueryClient, QueryClientProvider} from "react-query";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Home} from "./home/Home";
import LoginPage from "./admin/LoginPage";
import {Administration} from "./admin/Administration";
import {TablesEditor} from "./tables/TablesEditor";
import {EventReservationsById, EventReservationsByTag} from "./reservation/EventReservations";
import {VolunteerForm} from "./volunteer/VolunteerForm";
import {LocaleContextProvider} from "./commons/translations/LocaleContext";
import {TableReservationByTag} from "./tables/TableReservationByTag";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient();
const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        hasErrorBoundary: true,
        errorElement: (<div>404</div>),
        index: true
    },{
        path: "/events/:eventId",
        element: <EventReservationsById />,
    },{
        path: "/reserve/:eventTag",
        element: <EventReservationsByTag />,
    }, {
        path: "/reserve/:eventTag/:tableTag",
        element: <TableReservationByTag />,
    }, {
        path: "/admin",
        element: <Administration />
    }, {
        path: "/admin/tables/:event",
        element: <TablesEditor />
    }, {
        path: "/admin/login",
        element: <LoginPage />
    }, {
        path: "/volunteer",
        element: <VolunteerForm />
    }
]);

root.render(
  <LocaleContextProvider>
      <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
      </QueryClientProvider>
  </LocaleContextProvider>
);
